<template>
    <div class="m-auto" style="max-width: 960px;">
        <el-card header="基本信息" shadow="never" class="m-3">
            <el-row :gutter="8">
                <el-col :span="24" class="property-group">
                    <span class="property-name">状态</span>
                    <span class="property-value">
                        <router-link :to="'/audit-flow/detail?eventId='+plantContract.id" target="_blank">
                            {{plantContract.statusName}}
                        </router-link>
                    </span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">年份</span>
                    <span class="property-value">{{plantContract.year}}</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">编号</span>
                    <span class="property-value">{{plantContract.code}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="24" class="property-group">
                    <span class="property-name">名称</span>
                    <span class="property-value">{{plantContract.name}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">流出方式</span>
                    <span class="property-value">{{plantContract.outflowMethodName}}</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">土地行政区划</span>
                    <span class="property-value">{{plantContract.regionName}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">承包开始日期</span>
                    <span class="property-value">{{$moment.sd(plantContract.beginContractDate)}}</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">承包结束日期</span>
                    <span class="property-value">{{$moment.sd(plantContract.endContractDate)}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="24" class="property-group">
                    <span class="property-name">水发公司（甲方）</span>
                    <span class="property-value">
                        {{plantContract.companyName}}
                        <span class="text-info">
                            {{plantContract.companySignerName}}
                            （{{plantContract.companySignerMobile}}）
                        </span>
                    </span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="24" class="property-group">
                    <span class="property-name">种植户（乙方）</span>
                    <span class="property-value">
                        {{plantContract.farmerName}}
                        <span class="text-info">
                            {{plantContract.farmerSignerName}}
                            （{{plantContract.farmerSignerMobile}}）
                        </span>
                    </span>
                </el-col>
            </el-row>
        </el-card>

        <el-card header="担保人" shadow="never" class="m-3">
            <div v-for="(warrantor,index) in plantContract.warrantors" :key="index" class="bg-light rounded p-3 my-2">
                {{warrantor.receiverName}}
                （{{warrantor.receiverMobile}}）
            </div>
            <el-empty v-if="plantContract.warrantors.length==0" />
        </el-card>

        <el-card header="其他签署人" shadow="never" class="m-3">
            <div v-for="(otherSignatory,index) in plantContract.otherSignatories" :key="index"
                class="bg-light rounded p-3 my-2">
                {{otherSignatory.receiverName}}
                （{{otherSignatory.receiverMobile}}）
                {{otherSignatory.companyName}}
            </div>
            <el-empty v-if="plantContract.otherSignatories.length==0" />
        </el-card>

        <el-card header="内容" shadow="never" class="m-3">
            <div v-for="(plantContractLand,index) in plantContract.plantContractLands" :key="index"
                class="bg-light rounded p-3 my-2">
                <router-link :to="'/land/detail?id='+plantContractLand.landId" target="_blank">
                    {{plantContractLand.landName}}
                </router-link>
                （{{plantContractLand.landArea}} 亩）
            </div>

            <el-row :gutter="8">
                <el-col :span="8" class="property-group">
                    <span class="property-name">总面积</span>
                    <span class="property-value">{{plantContract.totalArea}} 亩</span>
                </el-col>
                <el-col :span="8" class="property-group">
                    <span class="property-name">种植积分单价</span>
                    <span class="property-value">{{plantContract.plantPointUnitPrice}} 元/亩</span>
                </el-col>
                <el-col :span="8" class="property-group">
                    <span class="property-name">总种植积分</span>
                    <span class="property-value">{{plantContract.totalPlantPoint}} 元</span>
                </el-col>
            </el-row>
        </el-card>

        <el-card header="待签约合同附件" shadow="never" class="m-3">
            <multi-file-uploader v-model="plantContract.draftContractAttachments" readonly />
            <el-empty v-if="plantContract.draftContractAttachments.length==0" />
        </el-card>

        <el-card header="其他附件" shadow="never" class="m-3">
            <multi-file-uploader v-model="plantContract.otherAttachments" readonly />
            <el-empty v-if="plantContract.otherAttachments.length==0" />
        </el-card>

        <el-card header="线下签约合同附件" shadow="never" class="m-3">
            <multi-file-uploader v-model="plantContract.signOfflineContractAttachments" readonly />
            <el-empty v-if="plantContract.signOfflineContractAttachments.length==0" />
        </el-card>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                id: this.$route.query.id,
                plantContract: {
                    warrantors: [],
                    otherSignatories: [],

                    plantContractLands: [],
                    totalArea: 0,
                    plantPointUnitPrice: 0,
                    totalPlantPoint: 0,

                    draftContractAttachments: [],
                    otherAttachments: [],
                },
            };
        },
        methods: {
            async loadPlantContract() {
                let response = await this.$axios.get('/api/PlantContract/GetPlantContract', { params: { id: this.id } });
                this.plantContract = response.data;
                document.title = this.plantContract.code;
            },
        },
        created() {
            document.title = this.$store.state.applicationName;

            this.loadPlantContract();
        },
    };
</script>

<style>
    .property-group {
        line-height: 40px;
    }

    .property-name {
        display: inline-block;
        color: gray;
    }

    .property-name::after {
        content: '：';
    }

    .property-value {
        display: inline-block;
    }
</style>